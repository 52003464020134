import React,{Fragment} from 'react'
import Footer from '../footer/Footer'
import Header from '../Header/Header'
import './LayOut.css'
const LayOut = ({children}) => {
    return (
        < Fragment className="wide">
            <Header/>
            <div style={{minHeight:"80vh",width:"50%"}}  className="--pad">
             {children}
            </div>
            <Footer/>
        </ Fragment>
    )
}

export default LayOut
