import React from 'react'
import Upload from '../Components/Upload'
import Technical from '../Components/Technical'
const UploadView = () => {
    return (
        <div>
            <Technical/>
            <Upload/>
        </div>
    )
}

export default UploadView
