import React from 'react'
import './payCover.css'
import Payment from '../Assets/PHOTO-2023-10-20-09-36-55.jpeg'
function PaymentCover() {
    return (
        <div className="payCover">
            <img src={Payment} alt="pay"/>
        </div>
    )
}

export default PaymentCover
