import React from 'react'
import './PaymentCards.css'
import Card1 from '../Assets/PHOTO-2023-10-20-09-36-54 2.jpeg'
import Card2 from '../Assets/PHOTO-2023-10-20-09-43-50 2.jpg'
import Card3 from '../Assets/PHOTO-2023-10-20-10-14-27 2.jpeg'
function PaymentCards() {
    return (
        <div className="payment_cards">
            <div className="payment_cards_three">
                <div className="igsce">
                   <h2>Disciplinary Measures</h2>
                   <img src={Card1} alt="igsce"/>
                    <h4>The right attitude</h4>
                 
                 
                  
                     <p>In every organization,cohesion comes with the right structure .
                        At Oriel we strive hard to ensure we teach kids the right morals.
                        This is top down approach,from management,staff,students and support services.</p>
                        <p>We also appeal to 
                            incumbent and current students
                             and parents to work in coordination to achieve the implementation of rules and policies.</p>
                    
                </div>
                <div className="bgsce">
                <h2>Christian Ethos</h2>
                   <img src={Card2} alt="igsce"/>
                   
                   
                    <h4>Prayer Sessions</h4>
                    <p>Our school is christian eccentric,
                        we prioritize teaching the word of God and gathering of believers.
                        We want to develop better communities and families through christian ethos .</p>
                        <p>Regular prayer sessions and bible
                             interactive quizzes are done often.
                             The aim is to keep the students engaged 
                             and knowledgeable in the word of God .
                             As a christian school we believe we have a part in moulding good citizens with right conduct.</p>
                       
                    </div>
                    <div className="cambridge">
                    <h2>Entertainment </h2>
                   <img src={Card3} alt="igsce"/>
                    <h4>Fashion and music events</h4>
                  
                   
                    <p>Fashion shows,
                        musical events,poetry and 
                        dance are all part of our DNA.We understand passions,interests
                        and the need for every learn to express themselves differently.
                        </p>
                        <p>We conduct gospel events where emerging gospel artists can showcase their talent.
                            The platform is also open for those who want to do normal entertainment events.</p>
                    
                    </div>
            </div>
            
        </div>
    )
}

export default PaymentCards
