import React from 'react'
import './ReUse.css'
import Physics from '../Assets/einstein-153422_1280.png'
import Bio from '../Assets/lungs-154282_640.png'
import Math from '../Assets/parabola-148973_1280.png'




function ReUse() {
    return (
        <div className="re-use">
            <h1>Bringing You high level learning.</h1>
           <div className="all_subjects__div">
               <div className="physics">
                    <img src={Physics} alt="physics"/>
                    <h4> Physics for IGCSE</h4>
                        <p>Providing the practical knowledge that equip 
                            the learner for better understanding of the world.At Oriel,
                            we set the fundamentals for lucrative career paths by teaching physics. </p>
                         
                        <div className="content">
                     
                     
                    </div>
               </div>
               <div className="physics">
                   <img src={Bio} alt="iot"/>
                   <h4>Biology</h4>
                        <p>Our aim is to develop the learners mind by understanding
                             the mechanics of the body and practically show the human anatomy.At Oriel,
                             we want to produce future neurosurgeons,GP's,Oncologists and Opticians. </p>
                          
                        <div className="content">
                      
                        
                    </div>
                   </div>
                   <div className="physics">
                   <img src={Math} alt="bio"/>
                   <h4>Math for problem solving</h4>
                        <p>As the world changes and become more digital.Everything is a model or an algorithm.
                            For our beloved students to penetrate through various industries,
                            we teach them Math for not just fun but for real world activities. </p>
                           
                        <div className="content">
                      
                      
                    </div>
                   </div>
                 

           </div>
        </div>
    )
}

export default ReUse
