import React,{useEffect}from 'react'
import { SET_LOGIN } from '../redux/features/auth/authSlice'
import { useDispatch } from 'react-redux'
import { getLoginStatus } from '../services/authServices'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
const useLoggedOutHook = (path) => {
    const dispatch=useDispatch()
    const navigate=useNavigate()
     useEffect(()=>{
        const useLogOutRedirect=async()=>{
      const isLoggedIn=await getLoginStatus()
         dispatch(SET_LOGIN(isLoggedIn))
         if(!isLoggedIn){
           toast.info("Session expired,please login again,thank you.")
           navigate(path)
           return
         }
        }
        useLogOutRedirect()
     },[path,navigate,dispatch])
}

export default useLoggedOutHook