import React from 'react'
import './ModulesPlay.css'
function ModulesPlay() {
    return (
        <div>
            <h1>Loading video play with lessons immediately</h1>
        </div>
    )
}

export default ModulesPlay
