import React from 'react'
import './Masonry.css'
import image1 from '../Assets/IMG_5918.jpg'
import image2 from '../Assets/IMG_5919.jpg'
import image3 from '../Assets/IMG_5920.jpg'
import image4 from '../Assets/IMG_5921.jpg'
import image5 from '../Assets/IMG_5922.jpg'
import image6 from '../Assets/PHOTO-2023-10-20-09-36-54 2.jpeg'
import image7 from '../Assets/IMG_5924.jpg'
import image8 from '../Assets/PHOTO-2023-10-20-09-36-55.jpeg'
import image9 from '../Assets/PHOTO-2023-10-20-09-43-50 2.jpg'
import image10 from '../Assets/PHOTO-2023-10-20-10-14-25.jpeg'
import image11 from '../Assets/PHOTO-2023-10-20-10-14-27 2.jpeg'
import image12 from '../Assets/PHOTO-2023-10-20-10-14-30 2.jpeg'
import image13 from '../Assets/PHOTO-2023-10-20-10-15-38.jpeg'
import image14 from '../Assets/PHOTO-2023-10-20-10-14-53.jpg'
import image15 from '../Assets/oriel2.jpeg'
import image16 from '../Assets/oriel10.jpeg'
import image17 from '../Assets/oriel5.jpeg'
import image18 from '../Assets/oriel6.jpeg'
import image19 from '../Assets/oriel12.jpeg'
import image20 from '../Assets/oriel7.jpeg'
import image21 from '../Assets/oriel8.jpeg'
import image22 from '../Assets/girls.jpeg'
import image23 from '../Assets/oriel3.jpeg'
import image24 from '../Assets/oriel9.jpeg'
const Masonry = () => {
    return (
        <div className="grid-wrapper">
            <div >
                <img src={image1} alt="gallery-image" />
            </div>
            <div >
                <img src={image2} alt="gallery-image" />
            </div>
            <div >
                <img src={image3} alt="gallery-image" />
            </div>
            <div >
                <img src={image4} alt="gallery-image" />
            </div>
            <div>
                <img src={image5} alt="gallery-image" />
            </div>
            <div >
                <img src={image7} alt="gallery-image" />
            </div>
            <div >
                <img src={image6} alt="gallery-image" />
            </div>
            <div >
                <img src={image8} alt="gallery-image" />
            </div>
            <div >
                <img src={image9} alt="gallery-image" />
            </div>
            <div >
                <img src={image10} alt="gallery-image" />
            </div>
            <div >
                <img src={image11} alt="gallery-image" />
            </div>
            <div >
                <img src={image12} alt="gallery-image" />
            </div>
            <div>
                <img src={image13} alt="gallery-image" />
            </div>
            <div >
                <img src={image14} alt="gallery-image" />
            </div>
            <div >
                <img src={image15} alt="gallery-image" />
            </div>
            <div >
                <img src={image16} alt="gallery-image" />
            </div>
            <div >
                <img src={image17} alt="gallery-image" />
            </div>
            <div >
                <img src={image18} alt="gallery-image" />
            </div>
            <div >
                <img src={image19} alt="gallery-image" />
            </div>
            <div >
                <img src={image20} alt="gallery-image" />
            </div>
            <div>
                <img src={image21} alt="gallery-image" />
            </div>
            <div >
                <img src={image23} alt="gallery-image" />
            </div>
            <div >
                <img src={image22} alt="gallery-image" />
            </div>
            <div >
            <img src={image24} alt="gallery-image" />
            </div>
        </div>
    )
}

export default Masonry
