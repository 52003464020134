import React from 'react'
import Register from '../Components/Register'
function RegisterScreen() {
    return (
        <div>
            <Register/>
        </div>
    )
}

export default RegisterScreen
