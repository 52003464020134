import React from 'react'
import useLoggedOutHook from '../useHook/useLoggedOutHook'
const Dashboard = () => {
    useLoggedOutHook('/login')
    return (
        <div>
        Dashboard
        </div>
    )
}

export default Dashboard
