import React, { Fragment } from 'react'


import Video from '../Components/Video'

import SocialMedia from '../Components/SocialMedia'
import Boards from '../Components/Boards'
import Brands from '../Components/Brands'
import ReUse from '../Components/ReUse'
import Review from '../Components/Review'
import RoundedCards from '../Components/RoundedCards'
import SideWays from '../Components/SideWays'
import Competence from '../Components/Competence'
import Chat from '../Components/Chat'
import Image4 from '../Assets/DrawKit Vector Illustration Project Manager (8).png'
function HomeScreen() {
  return (
    <Fragment>
      <Video />
      <SocialMedia />
      <Chat />
      <Boards />
      <Brands />

      <ReUse


      />

      <Review


      />

      <RoundedCards />



      <SideWays
        image={Image4}
        big="Our Curriculum"
        medium="Oriel International School is
       offering Cambridge International Curriculum,however this has been reorganized
       into a solution based hybrid curriculum with the purpose of tackling 
       the current national social-economic problem of youth unemployment."
        para2="Oriel International School have crafted a 
       unique solution based curriculum that has never been there and is coded as AAT3C+"
        para3="A=Academics that involve general curriculum which is commonly taught at Schools(IGCSE and HIGCSE)."
        para="A=Art we intend to have performing art including music,dance and drama."
        classic="3C+=Classical Christ Centered +Life Orientation(Character and personal development skills)"
        link="Read more"
      />
      <Competence />

    </Fragment>
  )
}

export default HomeScreen
