import React from 'react'
import './Footer.css'
import MainLogo from '../Assets/IMG_5924.jpg'
function Footer() {
    return (
        <div className="footer">
            <div className="footer-inner">
                <img src={MainLogo} alt="logo"/>
                <div className="Programs">
                    <h2> Terms for Enrollment</h2>
                    <ul className="Enroll">
                        <li>First Term:January-April</li>
                        <li>Second Term:May-August</li>
                        <li>Third Term:September-November</li>
                      
                    </ul>
                </div>
               
                <div className="Programs">
                    <h2>Place of Operation</h2>
                    <ul className="Enroll">
                        <li>Plot 8309,Maruapula</li>
                        <li>Behind Maruapula Complex</li>
                        <li>Call us:+267 72759556</li>
                      
                    </ul>
                </div>
                <div className="Programs">
                    <h2>Community Works</h2>
                    <ul className="Enroll">
                        <li>Girl based fees Promotions</li>
                        <li>Public Cleaning and Walk-ins</li>
                        <li>Educational Grants and Prizes</li>
                  
                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer
