import React from 'react'
import Login from '../Components/Login'

const LoginScreen = () => {
    return (
        <div>
             
            <Login/>
         
        </div>
    )
}

export default LoginScreen
