import React from 'react'
import './AI.css'
import Ai from '../Assets/ai.png'
function AI() {

    return (
        <div className="ai">
            <div className="ai-big">
                <img src={Ai} alt="ai"/>
                <aside className="aside-detail">
                    <h1>Artificial Intelligence</h1>
                    <h5>How to build useful Robot?</h5>
                    <p>A robot is a machine,
                        especially one programmable by a computer,
                        capable of carrying out a complex series of actions automatically .
                        A robot can be guided by an external control device,or control may be embedded within.</p>
                    <button className="ai-button">Enquire</button>
                </aside>
            </div>
        </div>
    )
}

export default AI
