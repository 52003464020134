import React from 'react'
import './chat.css'
import whatsApp from '../Assets/whatsapp-logo-png-2263 copy.png'
function Chat() {
    return (
        <div className="position-center">
            <div className="whatsApp">
            <a href=" https://wa.me/72759556"><img  src={whatsApp} alt="app"/></a>
            </div>
           
        </div>
    )
}

export default Chat