import React from 'react'
import Screen from '../Assets/DrawKit Vector Illustration Project Manager (18).png'
import './RoundedCards.css'
function RoundedCards() {
    return (
        <div className="roundedCard">
         <div className="display">
             <img src={Screen} alt="learning"/>
             <div className="list-content">
                 <h4>E-LEARNING BENEFITS</h4>
                 <div className="list_style">
                     <h3>A way of integrating students to learn technology.</h3>
                     <h3>It reduces the need for physical meetings but virtual.</h3>
                     <h3>It offers flexibility to keep students workflow easy.</h3>
                     <h3>It is efficient and no time limit to access lessons.</h3>
                     <h3>Its robust and caters direct user needs without others.</h3>
                 </div>
             </div>
         </div>
        </div>
    )
}

export default RoundedCards
