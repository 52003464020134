import React from 'react'
import './Review.css'
import iot from '../Assets/octopus-1220817_1280.png'
import music from '../Assets/guitar-1473400_640.png'
import Chemist from '../Assets/acetic-1299149_1280.png'

function Review() {
    return (
        <div className="re-use">
           <div className="all_subjects__div">
               <div className="physics">
                    <img src={iot} alt="physics"/>
                    <h4>Internet of things</h4>
                        <p>With the rapid growth of technology,
                            A.I,Machine Learning and Embedded
                             systems,we introduce our students 
                             to smart minimalistic design. </p>
                       
                        <div className="content">
                     
                     
                    </div>
               </div>
               <div className="physics">
                   <img src={Chemist} alt="iot"/>
                   <h4>Chemistry</h4>
                        <p>We have trained personnel and an
                             equipped laboratory to demonstrate and conduct 
                             different practicals for students to understand Chemistry better. </p>
                           
                        <div className="content">
                      
                        
                    </div>
                   </div>
                   <div className="physics">
                   <img src={music} alt="bio"/>
                   <h4>We do Music</h4>
                        <p>Talents in music and artistry spices life.We do music shows and talents 
                            exhibition,both internal and external to encourage learners to participate. </p>
                         
                        <div className="content">
                      
                        
                    </div>
                   </div>
                 

           </div>
        </div>
    )
}

export default Review
