import axios from 'axios'


export const getLoginStatus= async()=>{
    try {
        const response=await axios.get('/api/auth/status')
        return response.data
    } catch (error) {
        console.log(error)
    }
 
}
