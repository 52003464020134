import React from 'react'
import socketIO from 'socket.io-client';

import HomeScreen from '../Components/HomeScreen'
import EnrollScreen from '../Components/EnrollScreen'
import {Routes,Route} from 'react-router-dom'
import Artificial from '../Components/Artificial'
import RegisterScreen from '../Components/RegisterScreen'
import LoginScreen from '../Components/LoginScreen'
import PaymentScreen from '../Components/PaymentScreen'
import AlumniScreen from '../Components/AlumniScreen'
import TimeTable from '../Components/TimeTable'
import ModuleScreen from '../Components/ModuleScreen'
import NewsScreen from '../Components/NewsScreen'
import UploadView from '../Components/UploadView'
import AdminOneView from '../Components/AdminOneView'
import AdminView from '../Components/AdminView'
import ForgotScreen from '../Components/ForgotScreen'
import ResetPassword from '../Components/ResetPassword'
import BoardScreen from '../Components/BoardScreen'
import SideBar from '../sideBar/SideBar';
import LayOut from '../layout/LayOut';
import Dashboard from '../Dashboard/Dashboard';
import LibraryScreen from '../Components/LibraryScreen';

function Screen() {
    return (
        
        <Routes>
            <Route path="/" element={<HomeScreen/>}/>
          
            <Route path="/enroll" element={<EnrollScreen/>}/>
            <Route path="/register" element={<RegisterScreen/>}/>
                <Route path="/admin" element={<AdminOneView/>}/>
                
         <Route path="/profile" element={<BoardScreen/>}/>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/ai" element={<Artificial/>}/>
                    <Route path="/uploads" element={<UploadView/>}/>  
            <Route path="/modules" element={<ModuleScreen/>}/>
            <Route path="/location" element={<AdminView/>}/>
            <Route path="/students" element={<PaymentScreen/>}/>
            <Route path="/explore" element={<AlumniScreen/>}/>
            <Route path="/timetable" element={<TimeTable/>}/>
 
            <Route path="/library" element={<LibraryScreen/>}/>
            <Route path="/forgotpassword" element={<ForgotScreen/>} />
            <Route path="/resetpassword/:resetToken" element={<ResetPassword/>} />
            <Route path="/dashboard" element={
                 <SideBar>
                <LayOut>
                    <Dashboard/>
                </LayOut>
            </SideBar>}/>
        </Routes>
    
    )
}

export default Screen
