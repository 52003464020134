import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectName, SET_LOGIN } from '../redux/features/auth/authSlice'
import { toast } from 'react-toastify'
import './Header.css'
const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const name = useSelector(selectName)
    const logoutUser = async () => {
        try {
            await axios.get("/api/auth/logout")

        } catch (error) {
            console.log(error.message)
        }


    }
    const logout = async () => {
        await logoutUser()
        await dispatch(SET_LOGIN(false))
        navigate('/login')
    }
    return (
        <div className="overall-content">
            <div className="flex-container">
                <h3>
                    <span >Welcome,

                    </span>
                    <span className="name">{name}</span>
                </h3>
                <Link to="">  <button onClick={logout}>Logout</button></Link>
            </div>
            <hr />
        </div >
    )
}
export default Header
