import React from 'react'
import {FaFacebook,FaTwitter,FaInstagram,FaWhatsapp} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import './FooterLow.css'
function FooterLow() {
    return (
        <div className="social__icons">
            <div className="icons">
                <div className="fb">
                   <a href="https://web.facebook.com/orielinternational7"> <FaFacebook color="red" size="35"/></a>
                </div>
                <div className="Instagram">
                   <Link to=""> <FaInstagram color="red" size="35"/></Link>
                </div>
                <div className="twitter">
                    <Link to=""><FaTwitter color="red" size="35"/></Link>
                </div>
           
            </div>
        </div>
    )
}

export default FooterLow
