import React from "react";

import { useSelector } from "react-redux";
import {selectIsLoggedIn} from '../redux/features/auth/authSlice'
 export const ShowOnLogin=({children})=>{
    const loginStatus=useSelector(selectIsLoggedIn)
    if(loginStatus){
        return <>{children}</>
    }
}
export const ShowOnLogout=({children})=>{
    const loginStatus=useSelector(selectIsLoggedIn)
    if(!loginStatus){
        return <>{children}</>
    }
}