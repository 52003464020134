import { createSlice } from '@reduxjs/toolkit'
 const firstName=JSON.parse(localStorage.getItem("firstName"))

const initialState = {
  isLoggedIn:false,
  firstName:firstName?firstName:"",
  user:{
     firstName:"",
     lastName:"",
     course:"",
     email:""
  }
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
    SET_NAME(state,action){
         localStorage.setItem("firstName",JSON.stringify(action.payload))
         state.firstName=action.payload
    },
    SET_LOGIN(state,action){
      state.isLoggedIn = action.payload;
    },
    SET_USER(state,action){
      const profile=action.payload
      state.user.firstName = profile.firstName;
      state.user.email = profile.email;
      state.user.lastName = profile.lastName;
      state.user.course = profile.course;
    },
    }
});

export const {
SET_LOGIN,SET_USER,SET_NAME
} = authSlice.actions
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectName = (state) => state.auth.firstName;
export const selectUser = (state) => state.auth.user;
export default authSlice.reducer