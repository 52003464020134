import React from 'react'
import './Modules.css'
function Modules({video}) {
    return (
        <div className="video_for_teacher">
        <div className="the_actual_video">
            <video src={video}  controls></video>
        </div>
    </div>
    )
}

export default Modules
