import React from 'react'
import AI from './AI'
import ComputerVision from '../Components/ComputerVision'
import AICards from '../Components/AICards'
function Artificial() {
    return (
        <div>
            <AI/>
            <ComputerVision/>
            <AICards/>
        </div>
    )
}

export default Artificial
