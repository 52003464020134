import React from 'react'
import Code from '../Assets/hexagon-2307350_1280.png'
import Drone from '../Assets/quadrocopter-1658967_640.png'
import Model from '../Assets/sphere-6522553_640.png'
const AICards = () => {
    return (
        <div className="re-use">
        <div className="all_subjects__div">
            <div className="physics">
                 <img src={Code} alt="physics"/>
                 <h4>Programming</h4>
                     <p>At Oriel we teach our learners to be hands on,
                         with our competent teachers and world class scientific tools.
                         We equip your child for the best careers in the world. </p>
                        
                     <div className="content">
                  
                
                 </div>
            </div>
            <div className="physics">
                <img src={Model} alt="iot"/>
                <h4>3D Printing</h4>
                     <p>Modelling different shapes and objects using CAD softwares and 
                        advanced 3D machines to allow the student to be creative as they want. </p>
                         
                 
                </div>
                <div className="physics">
                <img src={Drone} alt="bio"/>
                <h4>Drone Designs</h4>
                     <p>Drones,quadrocopter,rovers and sample desk robots
                         using the basic AI models to create not just cool machines but functional. </p>
                     
                   
                </div>
              

        </div>
     </div>
    )
}

export default AICards
