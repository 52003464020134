import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import './Table.css'

function Table({header}) {
    return (
        <div>
          
            <div className="calendar-plus-table">
               <div className="time-table">
                   <div className="calendar">
                       <input type="date"/>
                   </div>
                   <div className>
                       <h3>All classes begin at 08:00AM to 03:00PM</h3>
                   </div>
                   <div className="list-of-forms">
                       <div className="classes-and-days">
                         <Link to="/Oriel - 2022 Prospectus_231003_135822.pdf" target='_blank' download><button>Download Brochure</button></Link>
                       </div>
                   </div>
               </div>
            </div>

        </div>
    )
}

export default Table
