import React from 'react'
import {Link} from 'react-router-dom'
import Staff from '../Assets/IMG_5919.jpg'
import Students from '../Assets/IMG_5922.jpg'
import Student1 from '../Assets/IMG_5923.jpg'
import './Competence.css'
function Competence() {
    return (
        <div className="competent_staff">
            <h1>Our Key Beliefs</h1>
            <div className="all_competency">
                <div className="staff">
                    <img src={Staff} alt="qualified"/>
                      <p>To provide an excellent and modern Christ centered education for secondary students.
                      </p>
                 
                </div>
                <div className="staff">
                    <img src={Student1} alt="qualified"/>
                      <p>To develop and prepare students for life by ensuring that their character is in accordance with christian values.
                      </p>
                  
                </div>
                <div className="staff">
                    <img src={Students} alt="qualified"/>
                      <p>To provide staff who are not only fully qualified but also those who act as positive role models to the learners.
                      </p>
                    
                </div>
            </div>
        </div>
    )
}

export default Competence
