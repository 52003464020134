import React from 'react'
import {Link} from 'react-router-dom'
import './Days.css'
function Days({monday,tuesday,wednesday,thursday,friday}) {
    return (
        <div className="week_days">
            <div className="link_to_days">
                <p><Link to="">Monday</Link></p>
                <p><Link to="">tuesday</Link></p>
                <p><Link to="">wednesday</Link></p>
                <p><Link to="">thursday</Link></p>
                <p><Link to="">friday</Link></p>
            </div>
        </div>
    )
}

export default Days
