import React from 'react'
import {NavLink,Link} from 'react-router-dom'
import './Technical.css'
const Technical = () => {
    return (
        <div className="teachers-overall">
            <div className="sub-nav-bar">
            <div className="web__sign_up">
      <a href="https://meet.google.com/vsd-gzwt-bzn?pli=1&authuser=0"> <button type="button" className="contact">Live Lessons</button></a>
           
       </div>
              <div className="header">
                  <h1>Admins Area</h1>
              </div>
                    <div className="the-links-to-logins-plus-register">
                      
                        <p><NavLink to="/notice">Log Out</NavLink></p>
                    </div>
        
                 </div>
        </div>
    )
}

export default Technical
