import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './ForgotPassword.css'
import { toast } from 'react-toastify'
import axios from 'axios'
const ForgotPassword = () => {
    const [email, setEmail] = useState()

    const emailPasswordHandle = (event) => {
        setEmail(event.target.value)
    }
     const forgot=async(userData)=>{
          try {
            const {response}= await axios.post("/api/auth/forgotpassword",userData)
            toast.success(response.data.message);
          } catch (error) {
            const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      toast.error(message);      
          }
     }
    const onSubmitHandle = async (event) => {
        event.preventDefault()
        if (!email) {
            return toast.error("Please enter email you registered with.")
        }

         const userData={
            email
         }
         await forgot(userData)
        setEmail('')

    }
    return (
        <div className="form-general">
            <div className="form-container">

                <form className="form" onSubmit={onSubmitHandle}>
                    <h1>Forgot Password</h1>
                    <div className="form-control">
                        <label>Enter Your Email</label>
                        <input type="email" name="" id=""
                            value={email}
                            onChange={emailPasswordHandle}
                            placeholder="forgotten password" />
                    </div>

                    <Link to="/login"> <span>Login</span></Link>
              <button typeof="submit">Email reset link</button> 
                    <Link to="/"> <span>Home</span></Link>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword
