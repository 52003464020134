import React from 'react'
import Student from '../Assets/oriel11.jpeg'
import Student2 from '../Assets/oriel8.jpeg'
import Student3 from '../Assets/oriel10.jpeg'
import Student4 from '../Assets/oriel1.jpeg'
import './SocialMedia.css'
function SocialMedia() {
    return (
        <div className="social-media">
              <h2>Grooming responsible citizens.</h2>
            <div className="social-icons">
              
                <div className="all_values">
                <img src={Student} alt="values"/>
               <div className="values">
                   <h3>Vision Statement</h3>
                   <p>Our vision is to provide vibrant 
                       and dynamic education experience
                        for each student for God's glory.</p>
               </div>
                </div>
                <div className="all_values">
                <img src={Student2} alt="values"/>
               <div className="values">
                   <h3>Mission Statement</h3>
                   <p>Oriel International is a classical 
                       Christ centered Cambridge School that exist 
                       to equip students with learning and purposeful living.</p>
               </div>
                </div>
                <div className="all_values">
                <img src={Student3} alt="values"/>
               <div className="values">
                   <h3>Core Objectives</h3>
                   <p>To enable a student to develop christian 
                       character and excel in all for the good of 
                       the society,nation and the glory of God.</p>
               </div>
                </div>
             
                <div className="all_values">
                <img src={Student4} alt="values"/>
               <div className="values">
                   <h3>School Motto</h3>
                   <p>In God i can(With God,there is nothing absolutely impossible.)</p>
               </div>
                </div>
            </div>
        </div>
    )
}

export default SocialMedia
