import React from 'react'
import AdminOne from '../Components/AdminOne'
const AdminOneView = () => {
    return (
        <div>
            <AdminOne/>
        </div>
    )
}

export default AdminOneView
