import React from 'react'
import DashBoard from '../Components/DashBoard'
import SideBar from '../Components/SIdeBar'
import ContactUs from '../Components/ContactUs'
const BoardScreen = () => {
    return (
        <div>
            <SideBar/>
        <ContactUs/>
          
        </div>
    )
}

export default BoardScreen
