import React from 'react'
import './ComputerVision.css'
import Bolt from '../Assets/robot_-_125165 (540p).mp4'
const ComputerVision = () => {
    return (
        <div className="robotics-mechanics-ai">
            <h1 style={{textAlign:"center",color:"red",fontSize:"28px"}} className='ai-header'>Embrace new advanced ways of learning with Oriel</h1>
            <div className="video-plus-tex">
                
                 <div className="data-plus-content">
                     <h1>Next Generation Designs</h1>
                     <p>At Oriel we believe in giving 
                         our children the wide options,in exploring future in demand
                         careers by building devices and programs as experiments.</p>
                         <p>We introduce circuitry,basics of design
                            ,material science and the use of Arduino boards and micro-controllers.</p>
                         <p>Artificial intelligence is the new gold,and we are trying hard to prepare our 
                            future engineers and designers to ge the fundamentals right.</p>
                        
                 </div>
                 <video src={Bolt}
                 muted
                 controls={false}
                 autoPlay
                 loop
                 ></video>
            </div>
        </div>
    )
}

export default ComputerVision
