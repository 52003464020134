import React from 'react'
import './Pay.css'
function Payment() {
    return (
        <div className="pay__all">
            <h1>BENEFITS FOR CHILDREN AT SCHOOL</h1>
            <div className="pay_content">
                <div className="terms">
                    <h3>Lunch is Provided</h3>
                    <p>Lunch is provided at school from Monday to Thursday.
                        Note that a specific fee is paid.
                        On Friday lunch is not provided as students nock off early.
                    
                    </p>
                </div>
                <div className="terms">
                    <h3>School Bus Available</h3>
                    <p>School bus is available to pick and drop students in different designated places or areas.
                       
                    </p>
                </div>
                <div className="terms">
                    <h3>24hr Full Surveillance</h3>
                    <p>We use smart system and 
                        24 hr security is available to 
                        monitor the surroundings 
                        for any emergency or security breaches.
                        The school also has safety requirements and equipment.
                        
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Payment
