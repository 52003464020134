import React from 'react'
import './AutoPlay.css'
function AutoPlay({header,video1,video2,video3}) {
    return (
        <div className="video_lectures_for_free">
            <h1>{header}</h1>
            <div className="real_video_lessons">
                <div className="first_video">
                <video src={video1}  controls ></video>
               
                </div>
                <div className="first_video">
                <video src={video2}  controls ></video>
          
                </div>
                <div className="first_video">
                <video src={video3}  controls ></video>
               
                </div>
             
            </div>
        </div>
    )
}

export default AutoPlay
