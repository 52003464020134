import React,{useState,useRef} from 'react'
import axios from 'axios'
import {GrNext,GrPrevious} from 'react-icons/gr'
import './pdf.css'
const ContactUs = () => {
    const [questions,setQuestions]=useState()
    const [teacherName,setTeacherName]=useState()
    const [postedBy,setPostedBy]=useState()
    const [error,setError]=useState()
    const questionsHandle=(event)=>{
      setQuestions(event.target.value)
    }
const teacherNameHandle=(event)=>{
     setTeacherName(event.target.value)
}

const postByHandle=(event)=>{
   setPostedBy(event.target.value)
}

    const formSubmit=async(event)=>{
       event.preventDefault()
    
       const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
    try {
        const {data}= await axios.post("/api/query/questions",{
            teacherName, questions,postedBy
        },config)
        localStorage.setItem("authToken",data.token)
         window.location('/login')
    } catch (error) {
        if(error){
           setError(error.message)
        }
    }
       setQuestions('')
       setTeacherName('')
       setPostedBy('')
    }
    return (
        <div className="contact-form-with-both-sides">
            <div className="sub-contact-form-div">
              
                <div className="sub-contact-the-form-side">
                    <h3>POST DASHBOARD</h3>
                    <form onSubmit={formSubmit}>
                        <div className="form-group">
                        <label>Question</label>
                        <textarea type="text" 
                        value={questions}
                        onChange={questionsHandle}
                        placeholder="your question"/>
                        </div>
                      <div className="form-group">
                      <label> Teachers Name</label>
                        <input type="text" 
                        value={teacherName}
                        onChange={teacherNameHandle}
                        placeholder="Teachers name"/>
                      </div>
                        
                     <div className="form-group">
                     <label> Posted By</label>
                        <input type="text" 
                        value={postedBy}
                        onChange={postByHandle}
                        placeholder="students name"/>
                     </div>
                    
                    <button type="submit">Post</button>
                    </form>
                   
                </div>
            </div>
        </div>
    )
}
export default ContactUs;
