import React from 'react'
import Enrollment from '../Components/Enrollment'
import './enrollBack.css'

function EnrollScreen() {
    return (
        <div className="back">
       
            <Enrollment/>

        </div>
    )
}

export default EnrollScreen
