import React,{useState} from 'react'
import {Link,useParams,useNavigate} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import './ResetPassword.css'
const ResetPassword = () => {
    const [passwordOne,setPasswordOne]=useState()
    const [passwordTwo,setPasswordTwo]=useState()
    const newPasswordHandle=(event)=>{
        setPasswordOne(event.target.value)
    }
    const navigate=useNavigate()
    const confirmPasswordHandle=(event)=>{
     setPasswordTwo(event.target.value)
    }
    const {resetToken}=useParams()
    const resetPassword=async(userData,resetToken)=>{
           try {
            const response=await axios.put(`/api/auth/resetpassword${resetToken}`,
            userData
            )
            return response.data
           } catch (error) {
            console.log(error.message)
           }
    }
    const onSubmitHandle= async(event)=>{
        event.preventDefault()
        if(passwordOne!==passwordTwo){
            return toast.error('Passwords do not match.')
        }
        const userData={
            passwordOne,passwordTwo
        }
        try {
            const data=await resetPassword(userData,resetToken)
            toast.success(data.message)
        } catch (error) {
          toast.error("Encountered a problem to get password reset. ")
        }
       
        setPasswordOne('')
        setPasswordTwo('')
    
    }
    return (
        <div className="form-general">
        <div className="form-container">
           
            <form className="form" onSubmit={onSubmitHandle}>
            <h1>Reset Password</h1>
                <div className="form-control">
                    <label>Enter New Password</label>
                    <input type="password" name="" id="" 
                    value={passwordOne}
                    onChange={newPasswordHandle}
                    placeholder=" new password"/>
                </div>
                <div className="form-control">
                    <label>Confirm Password</label>
                    <input type="password" name="" id=""
                    value={passwordTwo}
                    onChange={confirmPasswordHandle}
                    placeholder="confirm new password"/>
                </div>
               <Link to="/login"> <span>Login</span></Link>
                <button type="submit">Change Password</button>
               <Link to="/"> <span>Home</span></Link>
               
            </form>
        </div>
    </div>
    )
}

export default ResetPassword
