import React,{useState} from 'react'
import{useNavigate} from 'react-router-dom'
import {HiMenuAlt3} from 'react-icons/hi'
import Oriel from '../Assets/IMG_5924.jpg'
import SideBarItem from '../sideBar/SideBarItem'
import menu from '../data/menu'
import './Sidebar.css'
const SideBar = ({children}) => {
    const [isOpen,setIsOpen]=useState(true)
    const navigate=useNavigate()
    const toggle=()=>{
        setIsOpen(!isOpen)
    }
    const goHome=()=>{
        navigate('/')
    }
    return (
        <div  className="layout">
        <div className="side-bar" style={{width:isOpen? "230px" :"60px"}}>
            <div className="top-section">
                <div className="logo-top" style={{display:isOpen?"block":"none"}}>
                      <img src={Oriel}
                      onClick={goHome}
                      
                      alt="logo"/>
                      
                </div>
                <div className="bars"    style={{marginLeft:isOpen?"100px":"0px"}}  >
                    <HiMenuAlt3
                    size={35}
                     onClick={toggle}
                    />
                </div>
            </div>
           {menu.map((item,index)=>{
               return <SideBarItem
                       key={index}
                       item={item}
                       isOpen={isOpen}
               />
           })}
        </div>
          
         
            <main style={{paddingLeft:isOpen?"230px":"60px",transition: "all .5s"}}>
            {children}
            </main>
           
        </div>
    )
}

export default SideBar
