import React, { useState, useEffect } from 'react'
import ModulesPlay from '../Components/ModulesPlay'
import Modules from '../Components/Modules'
import Teaching from '../Assets/Curta - 8051.mp4'
import AutoPlay from '../Components/AutoPlay'
import Communication from '../Assets/School - 57245.mp4'
import School from '../Assets/Communication - 90426.mp4'
import Day from '../Assets/Curta - 8051.mp4'
import videoOne from '../Assets/Seo - 11473.mp4'
import videoTwo from '../Assets/Science - 5373.mp4'
import videoThree from '../Assets/Man - 115021.mp4'

function ModuleScreen() {


  return (
    <>

      <div>
        <Modules
          video={Teaching}
        />
        <ModulesPlay />


        <AutoPlay
          header="Physics Lessons"
          video1={Communication}
          video2={School}
          video3={Day}
        />
        <AutoPlay
          header="Mathematics Lessons"
          video1={videoOne}
          video2={videoTwo}
          video3={videoThree}
        />
        <AutoPlay
          header="Biology Lessons"
          video1={videoOne}
          video2={videoTwo}
          video3={videoThree}
        />


      </div>
    </>
  )
}

export default ModuleScreen
