import React from 'react'

function Map() {
    return (
        <div className="map">
          
            <div className="find-use">

          
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.868899949776!2d25.92057217493752!3d-24.628203001650494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ebb5bcb4ce2553f%3A0x861f52014174a5b3!2sOriel%20International%20School!5e0!3m2!1sen!2sbw!4v1700071311714!5m2!1sen!2sbw" width="100%" height="600" style={{border:0}} allowFullScreen="" loading="lazy" ></iframe>


            </div>
        </div>
    )
}

export default Map