import React from 'react'
import Time from '../Components/Time'
import Table from '../Components/Table'
import Days from '../Components/Days'
import Physics from '../Assets/einstein-153422_1280.png'
import Math from '../Assets/igcse-logo-invert.png'
import Generic from './Generic'
function TimeTable() {
    return (
        <div>
           <Table
       
          />
       <Generic/>
       
            
          
        </div>
    )
}

export default TimeTable
