import React,{useState,useEffect} from 'react'
import {useNavigate,Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import axios from 'axios'
const AdminOne = () => {
    const [teachers,setTeachers]=useState()
    const [email,setEmail]=useState()
    const [passWord,setPassWord]=useState()
    const navigate=useNavigate()
    const teacherHandle=(event)=>{
    setTeachers(event.target.value)
    }
    const emailHandle=(event)=>{
        setEmail(event.target.value)
    }
    const passWordHandle=(event)=>{
   setPassWord(event.target.value)
    }
    const handleSubmit=async(event)=>{
      event.preventDefault()
      if(email==="" && passWord==="" && teachers==="" ){
        return toast("Please fill in all form requirements")
    }
    if(!email && !passWord){
        return toast("You can not register an account without email and password.")
    }
    if(email && passWord){
        navigate("/uploads")
    }
     const config={
         headers:{
             "Content-Type":"application/json"
         }
     }
     try {
         const {data}= await axios.post("/api/authorization/teachers",{
             teachers, email,passWord
         },config)
         localStorage.setItem("authToken",data.token)
          window.location('/modules')
     } catch (error) {
         if(error){
            console.log(error.message)
         }
     }
      setTeachers("")
      setEmail("")
      setPassWord("")
    }
    return (
        <div className="register__all__form">
        <div className="form__content__register">
     
           
       
            <div className="register__submit">
          
                <form onSubmit={handleSubmit}>
                <h1 style={{textAlign:"center",color:"orange"}}>Teacher Authorization</h1>
                    <div className="form_detail">
                        <label>Teachers Name</label>
                        <input type="text" id=""
                        onChange={teacherHandle}
                        value={teachers}
                        placeholder="full name"/>
                    </div>
                 
               
                    <div className="form_detail">
                        <label>Email </label>
                        <input type="email" 
                        onChange={emailHandle}
                        value={email}
                        id="" placeholder="@example.com"/>
                    </div>
                
                    <div className="form_detail">
                        <label>Password</label>
                        <input type="password" 
                        onChange={passWordHandle}
                        value={passWord}
                        id="" placeholder="create a password"/>
                    </div>
                
                    <div className="form_button">
                     <button type="submit">Register</button>
                    </div>
                    <span> Already  have an admin account?<span><Link to="/admin">Login</Link></span></span>
                </form>
            </div>
        </div>
    </div>
    )
}

export default AdminOne
