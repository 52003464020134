import React,{useState} from 'react'
import './Login.css'
import axios from 'axios'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {SET_NAME,SET_LOGIN} from '../redux/features/auth/authSlice'
import Loader from '../Loader/Loader'
const Login = () => {
 
    const [email,setEmail]=useState()
    const [passWord,setPassWord]=useState()
    const [loading,setLoading]=useState()
    const navigate=useNavigate()
    const dispatch= useDispatch()
    const loginEmailHandle=(event)=>{
        setEmail(event.target.value)
    }
    const loginPassWordHandle=(event)=>{
        setPassWord(event.target.value)
    }
    const loginHandle=async(event)=>{
        event.preventDefault()
        if(!email || !passWord){
            return toast.error('Email and password can not be empty.')
         
        }
        if(!email){
            return toast.error('Enter an email to log in.')
        }
        if(passWord.length<6){
            return toast.error('You must provide your valid password to log in.')
        }
     
       const config={
           headers:{
               "Content-Type":"application/json"
           }
       } 
            setLoading(true)
       try {
           
            const {data}=await axios.post("/api/auth/login",
            {email,passWord},config)
          
            await dispatch(SET_LOGIN(true))
            await dispatch(SET_NAME(data.firstName))
            toast.success("Login Successfully.")
            navigate("/dashboard")
          setLoading(false)
         
       } catch (error) {
          setLoading(false)
       }
        setEmail('')
        setPassWord('')
      
    }
    return (
        <div className="register__all__form">
            {loading && <Loader/>}
        <div className="form__content__register">
        
            <div className="register__submit">
                <form className="Login-form" onSubmit={loginHandle}>
                <h1>Login in Your  Account</h1>
                    <div className="form_detail">
                        <label>Enter your email</label>
                        <input type="email" 
                        onChange={loginEmailHandle}
                        value={email}
                        id="" placeholder="your email"/>
                    </div>
                    <div className="form_detail">
                        <label>Enter Password</label>
                        <input type="password" 
                        onChange={loginPassWordHandle}
                        value={passWord}
                        id="" placeholder="enter your  password"/>
                    </div>
                    <span className="login">Have no account yet?<Link to="/register"><span>Register</span></Link></span>
                    <div className="form_button">
                        <button type="submit">Login</button>
                    </div>
                    <span>Forgot Password, <Link to="/forgotpassword"> create one </Link>?   </span>  
                </form>
            </div>
        </div>
    </div>
    )
}

export default Login
