import React from 'react'
import './Generic.css'
const Generic = () => {
  return (
    <div className='generic-message'>
        
       <p>Time table and dashboard will be available in January,now it will have to be updated.
        Thank you,
        for more information,you can email us or call on the following:
        orielinternational7@gmail.com,
       call us: +267 72759556 .
       </p>
        
        </div>
  )
}

export default Generic