import React,{useState,useEffect} from 'react'
import {Link,useNavigate} from 'react-router-dom'
import './register.css'

import {toast} from 'react-toastify'
import axios from 'axios'
import {SET_LOGIN,SET_NAME} from '../redux/features/auth/authSlice'
import { useDispatch } from "react-redux";
import Loader from '../Loader/Loader'
const Register = ({history}) => {
    const [firstName,setFirstName]=useState()
    const [lastName,setLastName]=useState()
    const [email,setEmail]=useState()
    const [passWord,setPassWord]=useState()
    const [course,setCourse]=useState()
    const [loading,setLoading]=useState(false)
    const navigate=useNavigate()
   const dispatch=useDispatch()
  
    const nameHandle=(event)=>{
        setFirstName(event.target.value)
    }
    const courseHandle=(event)=>{
        setCourse(event.target.value)
    }
    const lastNameHandle=(event)=>{
        setLastName(event.target.value)
    }
    const emailHandle=(event)=>{
        setEmail(event.target.value)
    }
    const passWordHandle=(event)=>{
        setPassWord(event.target.value)
    }
   
    const handleSubmit=async (event)=>{
        event.preventDefault()
       if(!email || !passWord || !firstName || !course || !lastName){
         return  toast.error("Please fill in all fields.")
       }
       if(!email || !passWord){
           return toast.error("Email and password should be filled.")
       }
       if(passWord.length<6){
           return toast.error("Password must be greater than 6 characters")
       }
       if(!email){
         return toast.error("Please enter a valid email")
       }
       const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
        setLoading(true)
    try {
        const {data}= await axios.post("/api/auth/register",{
            firstName, lastName,course,email,passWord
        },config)
           await dispatch(SET_LOGIN(true))
           await dispatch(SET_NAME(data.firstName))
        setLoading(false)
        toast.success("Registered successfully.")
        navigate('/dashboard')
   
    } catch (error) {
       
             setLoading(false)
        
    }
        setFirstName('')
       setLastName('')
       setEmail('')
       setCourse('')
       setPassWord('')
    }
    return (
        <div className="register__all__form">
            {loading &&<Loader/>}
            <div className="form__content__register">
         
               
           
                <div className="register__submit">
                    <form className="form-register" onSubmit={handleSubmit}>
                    <h1 style={{textAlign:"center",color:"orange"}}>Create A Student Account Here</h1>
                        <div className="form_detail">
                            <label>Enter Your Name</label>
                            <input type="text" id=""
                            onChange={nameHandle}
                            value={firstName}
                            placeholder="first name"/>
                        </div>
                        <div className="form_detail">
                            <label>Enter Last Name</label>
                            <input type="text" 
                            onChange={lastNameHandle}
                            value={lastName}
                            id="" placeholder="last name"/>
                        </div>
                   
                        <div className="form_detail">
                            <label>Email </label>
                            <input type="email" 
                            onChange={emailHandle}
                            value={email}
                            id="" placeholder="@example.com"/>
                        </div>
                        <div className="form_detail">
                            <label>Program Name</label>
                            <input type="text" 
                            onChange={courseHandle}
                            value={course}
                            id="" placeholder="eg IGSCE,BGCSE"/>
                        </div>
                        <div className="form_detail">
                            <label>Password</label>
                            <input type="password" 
                            onChange={passWordHandle}
                            value={passWord}
                            id="" placeholder="create a password"/>
                        </div>
                    
                        <div className="form_button">
                         <button type="submit">Submit</button>
                        </div>
                        <span> Already  have a student account?<span><Link to="/login">Login</Link></span></span>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register

