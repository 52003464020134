import React,{useState} from 'react'
import './Enrollment.css'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import Loader from '../Loader/Loader'
function Enrollment() {
    const [firstName,setFirstName]=useState()
    const [lastName,setLastName]=useState()
    const [email,setEmail]=useState()
    const [idNumber,setIdNumber]=useState()
    const[contactNumber,setContactNumber]=useState()
    const [guardianNumber,setGuardianNumber]=useState()
    const [courseName,setCourseName]=useState()
    const [loading,setLoading]=useState(false)
    const navigate=useNavigate()
    const navigateHandle=()=>{
        navigate('/alumni')

    }
    const nameHandle=(event)=>{
        setFirstName(event.target.value)
    }
    const guardianHandle=(event)=>{
        setGuardianNumber(event.target.value)
    }
   
    const courseHandle=(event)=>{
        setCourseName(event.target.value)
    }
    const contactHandle=(event)=>{
          setContactNumber(event.target.value)
    }
    const lastNameHandle=(event)=>{
        setLastName(event.target.value)
    }
    const emailHandle=(event)=>{
        setEmail(event.target.value)
    }
    const idNumberHandle=(event)=>{
        setIdNumber(event.target.value)
    }
    const handleSubmit=async (event)=>{
        event.preventDefault()
        if(!firstName || !lastName ||  !email || !idNumber
             || !courseName || !guardianNumber ||  !contactNumber){
                 return toast.error("Please fill in all form fields to enroll.")
             }
             if(!email){
                 return toast.error("Provide a valid email please.")
             }
           //  if(!idNumber.length<9){
              //  return toast.error("Please enter valid omang number.")
            //  }
              //
             if(!contactNumber){
                 return toast.error("Please enter contact number")
             }
             if(!guardianNumber){
                return toast.error("Please enter contact number")
            }
          
        const config={
            headers:{
                "Content-Type":"application/json"
            }
        } 
            setLoading(true)
         try {
             const {data}= await axios.post("/api/enroll/students",{
                firstName,lastName,courseName,idNumber,email,contactNumber,guardianNumber
             },config)
             localStorage.setItem("authToken",data.token)
             setLoading(false)
         } catch (error) {
             toast.success("Successfully enrolled,wait for our email.")
             setLoading(false)
         }
        setFirstName('')
        setLastName('')
        setEmail('')
        setCourseName('')
        setContactNumber('')
        setIdNumber('')
        setGuardianNumber('')
    
        navigateHandle()
    }
    return (
        <div className="enroll-all">
         {loading && <Loader/>}
            <div className="enroll">
                <form className="form-all" onSubmit={handleSubmit}>
                <h1 style={{textAlign:"center",color:"orange"}}>Enroll Your Child Here</h1>
                <h4  style={{textAlign:"center",color:"orange"}}> (Please provide real data,no spam)</h4>
                    <div className="names">
                        <label>First Name</label>
                        <input type="text" 
                        onChange={nameHandle}
                        value={firstName}
                        placeholder="Your first Name"/>
                    </div>
                    <div className="second-names">
                        <label>Last Name</label>
                        <input type="text" 
                        onChange={lastNameHandle}
                        value={lastName}
                        placeholder="Your Last Name"/>
                    </div>
                    <div className="preferred-course">
                        <label>Course Name</label>
                        <input type="text" 
                        onChange={courseHandle}
                        value={courseName}
                        placeholder="Your course Name"/>
                    </div>
                    <div className="id-number">
                        <label>Omang Number</label>
                        <input type="text"
                        onChange={idNumberHandle}
                        value={idNumber}
                        placeholder="Your omang Number"/>
                    </div>
                    <div className="email">
                        <label> Email Address</label>
                        <input type="email" 
                        onChange={emailHandle}
                        value={email}
                        placeholder="email"/>
                    </div>
                    <div className="phone-number">
                        <label>Contact Number</label>
                        <input type="text" 
                        onChange={contactHandle}
                        value={contactNumber}
                        placeholder="contact number"/>
                    </div>
                    <div className="guardian-number">
                        <label>Guardians Number</label>
                        <input type="text" 
                        onChange={guardianHandle}
                        value={guardianNumber}
                        placeholder="Guardians number"/>
                    </div>
                   
                    <div className="btn-submit">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Enrollment
