import React from 'react'
import ForgotPassword from './ForgotPassword'
const ForgotScreen = () => {
    return (
        <div>
            <ForgotPassword/>
        </div>
    )
}

export default ForgotScreen
