import React from 'react'
import {BsArrowRight} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import Learning from '../Assets/oriel7.jpeg'
import Social from '../Assets/social-media-5187243_1280.png'
import './Boards.css'
function Boards({image,notice,view}) {
    return (
        <div className="boards">
            <h1 >Why Us?</h1>
            <div className="boards-lay-out">
               <div className="glass">
                   <div className="blue-layer">
                   <img src={Learning}alt/>
                   </div>
             
              <h3>Special Discount</h3>
                   <div className="list_style">
                     <p>We have 5% girl child discount if you enroll today.</p>
                   </div>
                  <Link to="/enroll"> <button className="btn_glass">Enroll</button></Link>
               </div>
               <div className="gradient_promo">
                   <div className="glass-content">
                       <img src={Social} alt="social"/>
                       <p>Because of  what we offer,even though we are
                           offering a Cambridge International Curriculum,it has been reorganized into
                           a solution based hybrid curriculum.
                       </p>
                       <img src={Social} alt="social"/>
                   </div>
                   <br/>
                   <div className="glass-content">
                   <img src={Social} alt="social"/>
                       <p>Access learning materials 24/7.</p>
                       <img src={Social} alt="social"/>
                   </div>
                   <br/>
                   <div className="glass-content">
                   <img src={Social} alt="social"/>
                       <p>Unlimited teacher interaction using virtual technologies.</p>
                       <img src={Social} alt="social"/>
                   </div>
                   <br/>
                   <div className="glass-content">
                   <img src={Social} alt="social"/>
                       <p>We have theater training and performance with 
                           the objective of identity and developing talents into professionals.</p>
                       <img src={Social} alt="social"/>
                   </div>
                   <br/>
                   <div className="glass-content">
                   <img src={Social} alt="social"/>
                       <p>We provide a unique education environment,enriched by active contact
                           and cooperation with all stakeholders including the general community.
                       </p>
                       <img src={Social} alt="social"/>
                   </div>
                 
               </div>
            </div>
        </div>
    )
}

export default Boards
