import React from 'react'
import './Video.css'
import School from '../Assets/oriel.mp4'
import {Link,useNavigate} from 'react-router-dom'
function Video() {
    const navigate=useNavigate()
    return (
        <div className="overall-class">
            <div className="video">
                <video src={School} autoPlay muted loop/>
            </div>
            <div className="video-overlay">
            <h1 className="bold">Welcome to Oriel International School.</h1>
                <h1 className="big">Learning meets,Innovation and Experience.</h1>
         
               <Link to="/login"> <button className="lesson"
                
                >Watch Lesson</button></Link>
                <Link to="/register"><button className="register">Register</button></Link>
            </div>
        </div>
    )
}

export default Video
