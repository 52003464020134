import React from 'react'
import {Link} from 'react-router-dom'

import './SideWays.css'
function SideWays({image,big,medium,para,para2,para3,classic}) {
    return (
        <div className="overall-side">
         
          <div className="content">
              <h1>{big}</h1>
              <p>{medium}</p>
              <p>{para2}</p>
              <p>{para3}</p>
              <p>{para}</p>
              <p>{classic}</p>
        
          </div>
          <img src={image} alt="images"/>
       
        </div>
    )
}

export default SideWays
