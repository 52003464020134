import React,{useState,useEffect,Fragment} from 'react'
import './SideBar.css'

import {Link, useNavigate} from 'react-router-dom'
import {CgProfile} from 'react-icons/cg'
import {AiFillMessage} from 'react-icons/ai'
import {TbReportSearch} from 'react-icons/tb'
import {GiTakeMyMoney} from 'react-icons/gi'
const SIdeBar = () => {
    const [profile,setProfile]=useState(null)
    const navigate=useNavigate()
    const profileHandle=(event)=>{
        setProfile(URL.createObjectURL(event.target.files[0]))
    }
    const logOutHandle=()=>{
        if(localStorage.getItem("authToken")){
        window.location('/login')
        }
    }
    return (
        <Fragment>
 <div className="side-bar-rounded-all">
            <div className="profile-perfect">
            <h2 style={{color:"orange"}}>Create Your Profile Account</h2>
            <input type="file" onChange={profileHandle}/>
            <img src={profile} alt="profile" style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            </div>
       
          
                  <div className="inbox-messages">
                 < Link to="/login">
                      <AiFillMessage
                      color="orange"
                      size={38}
                      />
                  </Link>
                  </div>
                  <div className="school-reports">
              <Link to="/login">
                      <TbReportSearch
                         color="orange"
                         size={38}
                      />
                  </Link>
                  </div>
                  <div className="school-reports">
             <Link to="/login">
                      <GiTakeMyMoney
                         color="orange"
                         size={38}
                      />
                 </Link>
                  </div>
                 <Link to="/login"> <button style={{width:"130px",height:"30px",
                  outline:"none",borderStyle:"none",background:"red",
                  color:"white"}}>Log Out</button></Link>
           
        </div>
      
        </Fragment>
       
    )
}

export default SIdeBar
