import React from 'react'
import Library from '../Components/Library'
const LibraryScreen = () => {
  return (
    <div>

    <Library/>
    </div>
  )
}

export default LibraryScreen