import React from 'react'
import Map from '../Components/Map'
const AdminView = () => {
    return (
        <div>
            <Map/>
        </div>
    )
}

export default AdminView
