import React from 'react'
import Payment from '../Components/Payment'
import PaymentCover from '../Components/PaymentCover'
import PaymentCards from '../Components/PaymentCards'
function PaymentScreen() {
    return (
        <div>
            <PaymentCover/>
            <PaymentCards/>
            <Payment/>
        </div>
    )
}

export default PaymentScreen
