import React,{Fragment,useEffect} from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './Components/NavBar'
import Screen from './Screens/Screen'
import FooterLow from './Components/FooterLow'
import Footer from './Components/Footer'
import axios from 'axios'
import { getLoginStatus } from './services/authServices';
import { useDispatch } from 'react-redux';
import { SET_LOGIN } from './redux/features/auth/authSlice';
axios.defaults.withCredentials=true
function App() {
  const dispatch=useDispatch()
  useEffect(()=>{
    async function loginStatus(){
      const status=await getLoginStatus()
       dispatch(SET_LOGIN(status))
    }
    loginStatus()
  },[dispatch])
  return (
<Fragment>
 <ToastContainer/>


      <NavBar/>
     
    <Screen/>
     <Footer/>
     <FooterLow/>
   
    </Fragment>
  );
}

export default App;
