import React,{Fragment} from 'react'
import './LibraryCard.css'

const LibraryCard = ({bookData}) => {
  if(bookData==undefined){
    return
  }
  return (
   <>
     {
     
      bookData.map((item)=>{
        let thumbnail=item.volumeInfo.imageLinks.thumbnail
        let title=item.volumeInfo.title
        if(thumbnail!=undefined && title!=undefined){
          return (  
            <div className='main-library-card'>
            <div className='library-details-container'>
            <img src={ thumbnail} alt="image-for-the-book"/>
            <div className='book-description'>
                <h4>{title}</h4>
            <p>{item.volumeInfo.subtitle?item.volumeInfo.subtitle:<p>The brief description is not available.</p>}
  
            </p>
                     <button>Book Pages {item.volumeInfo.pageCount}</button>
            </div>
         </div>
         </div>
     
          )
        }
        
        })
     }
     
    </>
   

        
      
  )
}

export default LibraryCard