import React from 'react'
import './Brands.css'

import Brand1 from '../Assets/oriel2.jpeg'
import Brand2 from '../Assets/oriel9.jpeg'
import Brand3 from '../Assets/oriel5.jpeg'
import Brand4 from '../Assets/oriel3.jpeg'
function Brands() {
    return (
        <div className="brands-main">
            <h1>Our Range of School Uniforms</h1>
            <div className="brand-images">
              
               
              
                    
                    <img src={Brand1} alt="bots"/>
                    <img src={Brand2} alt="cambridge"/>
                
                 
                   <img src={Brand3} alt="G"/>
                    <img src={Brand4} alt="BRANDING"/>
                
                  
              
            </div>
        </div>
    )
}

export default Brands
