import React from 'react'
import Masonry from './Masonry'
function AlumniScreen() {
    return (
        <div>
        
    <Masonry/>
        </div>
    )
}

export default AlumniScreen
