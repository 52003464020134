
import React,{useState,Fragment} from 'react'
import {RiMenu3Line,RiCloseLine} from 'react-icons/ri'
import {Link, NavLink} from 'react-router-dom'
import {ShowOnLogin,ShowOnLogout} from '../protect/HiddenLinks'
import { FaHome } from "react-icons/fa";
import { AiFillSchedule } from "react-icons/ai";
import { FaRegCheckSquare } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { FaUserGraduate } from "react-icons/fa";
import { FaRobot } from "react-icons/fa";
import { SiLibrarything } from "react-icons/si";
import { MdLogin } from "react-icons/md";
import Red from '../Assets/IMG_5924.jpg'
import './NavBar.css'

const NavBar = (props) => {
    const [toggle,setToggle]=useState(false)
    const toggleHandle=()=>{
        setToggle(false)
    }
    const toggleOpen=()=>{
        setToggle(true)
    }
    return (
       
 <div className="Sage__web__navbar">
<div className="Sage__web__logo">
     <Link to="/">  <img src={Red} alt="logo"/></Link>
        </div>
        <div className="Sage__web__links">
       <p> <Link to="/" >Home</Link></p>
    <p> <Link to="/timetable">Schedule</Link></p>
    <ShowOnLogin>
   
    <p> <Link to="/modules">Modules</Link></p>
    </ShowOnLogin>
    <p> <Link to="/enroll">Enrollment</Link></p>
    <p > <Link to="/admin">Admins</Link></p>
    <p > <Link to="/location">Location </Link></p>
 
    <p> <Link to="/students">Students</Link></p>
    <ShowOnLogin>
    <p> <Link to="/uploads">Uploads</Link> </p>
    </ShowOnLogin>
 
   
  
   {/* <p> <NavLink to="/profile">Profile</NavLink></p>  */} 
    <p> <Link to="/ai">A.I</Link></p>
    <p> <Link to="/library">Library</Link></p>
   <ShowOnLogin>
 
    <p className="dashboard"> <NavLink to="/dashboard">Dashboard</NavLink></p>
    </ShowOnLogin>
   <ShowOnLogout>
    <p> <Link to="/login">Login</Link></p>
    </ShowOnLogout>
    <div className="Sage__web__sign_up">
   <Link to="/explore"> <button type="button" className="contact">Explore Place</button></Link>
   </div>
       </div>
       <div className="Sage__web__navbar__menu">
       <div className="Sage__web__logo__min">
          <Link to="/">  <img src={Red} alt="logo"/></Link>
        </div>
        <div className="menu_Mobile">
        {toggle?<RiCloseLine 
      style={{cursor:"pointer"}}
        color="red" size="43"
                 onClick={toggleHandle}/>:<RiMenu3Line 
            style={{cursor:"pointer"}}

                 color="red" size="33"
                 onClick={toggleOpen}/>}

        </div>
        <div className="Sage__web__mobile__links scale-up-center">
        {toggle &&(
                     <div className="mobile-links-nav">
               <p> <Link to="/" > <FaHome/> Home</Link></p>
    <p> <Link to="/timetable"> <AiFillSchedule/> Schedule</Link></p>
    <ShowOnLogin>
   
    <p> <Link to="/modules">Modules</Link></p>
    </ShowOnLogin>
    <p> <Link to="/enroll"> <FaRegCheckSquare/> Enrollment</Link></p>
   
    <p > <Link to="/admin"> <RiAdminFill/> Admins </Link></p>
 
    <p> <Link to="/students"> <FaUserGraduate/> Students</Link></p>
    <ShowOnLogin>
    <p> <Link to="/uploads">Uploads</Link> </p>
    </ShowOnLogin>
 
   
  
   {/* <p> <NavLink to="/profile">Profile</NavLink></p>  */} 
    <p> <Link to="/ai"> <FaRobot/> A.I</Link></p>
    <p> <Link to="/library"> <SiLibrarything/> Library</Link></p>
   <ShowOnLogin>
 
    <p className="dashboard"> <NavLink to="/dashboard">Dashboard</NavLink></p>
    </ShowOnLogin>
   <ShowOnLogout>
    <p> <Link to="/login"> <MdLogin/> Login</Link></p>
    </ShowOnLogout>

    <div className="Sage__web__sign_up">
   <Link to="/explore"> <button type="button" className="contact">Explore Place</button></Link>
   </div>
                    </div>
                    

                 )} 


           </div>


      </div>

       </div>
         
    )
}

export default NavBar

