import React,{useState,useEffect} from 'react'
import './Library.css'
import axios from 'axios'
import Lib from '../Assets/PHOTO-2023-10-20-09-36-54 2.jpeg'
import LibraryCard from './LibraryCard'
import {toast} from 'react-toastify'
const Library = () => {
    const [search,setSearch]=useState()
    const searchResults=(event)=>{
       setSearch(event.target.value)
    }
    const [bookData,setBookData]=useState([])
    const bookSearch=async()=>{
        if(!search){
            return toast.error("Search a book by subject or title.")
        }
        try {
            if(search){
                const res= await fetch('https://www.googleapis.com/books/v1/volumes?q='+search+'&key=AIzaSyC7JNZTnAeJOxgUlU5Tjriq-pH3sTA_MSo')
                const data= await res.json()
                setBookData(data.items)
                console.log(data.items)
              
                } 
               
                toast.success("Successfully brought results,scroll down.")  
                
        } catch (error) {
            return toast.error (error.message)
        }
       
         
    
      
    }
    return (
        <div className='main-library'>
               <div className='library-container'>
                <div className='lib-overlay'></div>
            <img src={Lib} alt="books"/>
            <div className='library-info'>
                <h1>Search books by title and read.</h1>
                <div className='buttons'>
                    <input type="text" value={search}  
                    onChange={searchResults} 
                 
                    placeholder='search books here'/>
                    <button onClick={bookSearch} >Search</button>
                </div>
            </div>
        </div>  
        <div className='library-cards'>
            {
            <LibraryCard bookData={bookData}/>
            }
           
         
        </div>
        </div>
       
    )
}

export default Library
