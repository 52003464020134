import React from 'react'
import './Footer.css'
const Footer = () => {
    return (
        <div className="footer-all">
            <div className="footer-main">
                <p>All Rights Reserved 2023</p>
            </div>
        </div>
    )
}

export default Footer
